import {Button} from '../components/buttons';
import anime from 'animejs';
import {useEffect, useRef} from 'react';
import {H2, H1, H3} from '../components/headers';

function About(){
    const ref = useRef(null);

    const animate = () => {
        anime.timeline({loop:false}).add({
            targets: ref.current.querySelectorAll('.morph path'),
            strokeDashoffset: [anime.setDashoffset, 0 ],
            easing: 'easeOutExpo',
            duration: 2800
        });
    }

    useEffect(() => {
        const observer = new IntersectionObserver(
        (entries, observer) => {
            entries.forEach(entry => {
            if (entry.isIntersecting) {
                animate();
                observer.disconnect();
            }
            });
        },
        { threshold: 0.1 } // Trigger when at least 10% of the element is visible
        );

        if (ref.current) {
        observer.observe(ref.current);
        }

        return () => {
        if (ref.current) {
            observer.unobserve(ref.current);
        }
        };
    }, []);
    return(
        <div id="about" className="small-container center flex justify">
            <div className="flex row center" style={{width: '60%'}}>
                <H2 text="Rewards Optimization"/>
                <div className="line"></div>
                <p style={{maxWidth: '32rem'}}> We're dedicated to fostering an inclusive presence within the blockchain community, driven by our fervor for empowering protocols, developers, and participants to shape the decentralized future.</p>
            </div>

        </div>
    );
}

export default About;