import {useRef, useEffect} from 'react';
import anime from 'animejs';
import {Button} from '../components/buttons';


function Protocol({project, percentage, src, i, href}){
    const ref = useRef(null);
    const animate = () => {
        anime.timeline({loop:false}).add({
            targets: ref.current,
            translateY: [20, 0],
            opacity: [0,1],
            easing: 'easeOutExpo',
            duration: 1000,
            delay: 250 * i
        });
    }
    useEffect(() => {
        const observer = new IntersectionObserver(
        (entries, observer) => {
            entries.forEach(entry => {
            if (entry.isIntersecting) {
                animate();
                observer.disconnect();
            }
            });
        },
        { threshold: 0.1 } // Trigger when at least 10% of the element is visible
        );

        if (ref.current) {
        observer.observe(ref.current);
        }

        return () => {
        if (ref.current) {
            observer.unobserve(ref.current);
        }
        };
    }, []);

    return(
        <div ref={ref} className="protocol">
            <div className="flex row" style={{width: '30%'}}>
                <img className="margin-right" style={{height: '1.25rem'}} src={src}/>
                <p>{project}</p>
            </div>
            <div style={{marginRight: 'auto'}}>
            <p>APY: {percentage}</p>
            </div>
            <Button text="Stake" href={href} target="_blank"/>
        </div>
    );
}

export default Protocol;