import React from 'react';
import logo from './logo.svg';
import './App.css';
import Landing from './pages/Landing';
import About from './pages/about';
import Protocols from './pages/protocols';
import Contact from './pages/contact';
import Navbar from './components/navbar';
import Footer from './components/footer';

function App() {
  return (
    <div className="wrapper">
      <Navbar/>
        <Landing/>
        <About/>
        <Protocols/>
      <Footer/>
    </div>
  );
}

export default App;
