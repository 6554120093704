import {H2, H1, H3, SmallHeader} from '../components/headers';
import {Button} from '../components/buttons';
import Card from '../components/card';
import Protocol from '../components/protocol';
import {useEffect, useRef} from 'react';
import anime from 'animejs';




function Protocols(){
    const ref = useRef(null);
    const animate = () => {
        anime.timeline({loop:false}).add({
            targets: ref.current,
            translateY: [20, 0],
            opacity: [0,1],
            easing: 'easeOutExpo',
            duration: 1000,
            delay: 250 
        });
    }
    useEffect(() => {
        const observer = new IntersectionObserver(
        (entries, observer) => {
            entries.forEach(entry => {
            if (entry.isIntersecting) {
                animate();
                observer.disconnect();
            }
            });
        },
        { threshold: 0.1 } // Trigger when at least 10% of the element is visible
        );

        if (ref.current) {
        observer.observe(ref.current);
        }

        return () => {
        if (ref.current) {
            observer.unobserve(ref.current);
        }
        };
    }, []);

    return(
        <div id="networks" className="container flex col justify center margin-bottom ">
            <img ref={ref}  id="eth" src="./img/Ethereum_Coin.png"/>
            <div className="flex center justify col margin-bottom">
                <H2 text={"Our Protocols"}/>
                <p style={{textAlign: 'center', width: '30rem'}}>benefit from our engineering and protocol expertise to earn consistent staking rewards.</p>
            </div>
            <div className="margin-top"></div>
            <Protocol src={'./img/ethereum.svg'} project="ETHEREUM" percentage="5.92%" i={1} href={'https://beaconcha.in/validator/0x91c77cfecd97f9cbc2c302ad343faa1961fd9a9e59c7faf17d3d793a5ca0ae59c2396c2dbfea8d07779dd04243ad9deb'}/>
            <Protocol src={'./img/avalanche.svg'} project="AVALANCHE" percentage="6.40%" i={2} href={'https://avascan.info/staking/validator/NodeID-HrGKtuSRaRj9b4CNy2JeLfv1WAULmhwtF'}/>
            <Protocol src={'./img/Band.svg'} project="BAND" percentage="COMING SOON" i={3}/>

        </div>
    );
}

export default Protocols;