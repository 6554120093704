import {H1, H3} from './headers.js';
import {Button} from './buttons';

function Footer(){
    return(
        <div id="footer" className={"footer flex row center justify"}>
            
        
                <div>
                    <div className="flex row center">
                        <img style={{width: '3rem', marginRight: '0.25rem'}} src="./img/logo.svg"/>
                        <h2>cedarsync</h2>
                    </div>
                    
                    <p>Digital Staking Solutions with Ease</p>
                </div>

                <div>
                     <H3 text={'follow us on'}/>
                     <div className="flex row margin-top">
                        <Button text={'Twitter'} href={"https://twitter.com/Cedar_Sync"}  target="_blank"/>
                        <div className="margin-right"></div>

                     </div>
                     <p className={'margin-top'}>support@cedarsync.com</p>
                </div>
            
        </div>
    );
};

export default Footer;